import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { ReactComponent as OpenSeaIcon } from "../../assets/mint/opensea.svg";
import { ReactComponent as LogoWide } from "../../assets/images/logo-wide.svg";
import { DefaultButton } from "../buttons/DefaultButton";
import { ReactComponent as MenuBurgers } from "../../assets/icons/menu-bugers.svg";
import { FormattedMessage } from "react-intl";
import { LanguageSelector } from "./LanguageSelector";
import { ReactComponent as DiscordLogo } from "../../assets/images/discord.svg";
import {useContext, useLayoutEffect, useRef, useState} from "react";
import { useOnBlur } from "../../app/utils";
import {DISCORD_URL, useHomeUrl, useSiteLinksPrefix} from "../../config";
import { OnNavHandler, ScrollablePoint } from "../../App";
import {useWeb3React} from "@web3-react/core";
import {shortenAccountNumber} from "../../eth/ethUtils";
import {useDispatch} from "react-redux";
import {setConnectorModalOpen} from "../../app/store";
import {CommonContext} from "../../index";
import {Language} from "../../app/translation";

interface MenuElement {
    name: string;
    url: string;
}

export function Header(props: {onNav: OnNavHandler}) {
    const commonContext = useContext(CommonContext);
    const {language, setLanguage} = commonContext;

    const HOME_URL = useHomeUrl();
    const SITE_LINKS_PREFIX = useSiteLinksPrefix();

    const dispatch = useDispatch();

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const menuRef = useRef<HTMLDivElement|null>(null);
    const burgerRef = useRef<HTMLDivElement|null>(null);

    const {account} = useWeb3React();

    useOnBlur(menuRef, ()=>setIsMenuOpen(false), [], [burgerRef]);

    const menuElements = [
        {name: "Story", url: SITE_LINKS_PREFIX+"#story"},
        {name: "Utility", url: SITE_LINKS_PREFIX+"#utility"},
        {name: "FAQ", url: SITE_LINKS_PREFIX+"#faq"}
    ] as MenuElement[];

    const menuClick = (menuEl: MenuElement) => {
        setIsMenuOpen(false);
        window.location.href = menuEl.url;
        if (menuEl.url.startsWith('#')) props.onNav(menuEl.url.substring(1) as ScrollablePoint);
    };

    return <div className="flex justify-center w-full font-sofia-pro xl:mt-8">
        <div className="relative flex p-6 gap-4 md:gap-8 shadow-dark-shadow items-center w-full max-w-xl xl:rounded-2xl shrink-0 bg-white" style={ {
            maxWidth: "1440px"
        }}>
            <a href={HOME_URL}><Logo className="h-16 md:hidden shrink-0" /></a>
            <a href={HOME_URL}><LogoWide className="h-16 hidden md:block shrink-0" /></a>
            <div className="grow flex justify-center">
                <div className="hidden lg:flex justify-between w-full" style={{maxWidth: "280px"}}>
                    {menuElements.map(el => <div key={el.name} className="text-light-dark hover:text-active font-bold cursor-pointer"
                        onClick={()=>menuClick(el)}
                    >
                        <FormattedMessage id={el.name} />
                    </div>)}
                </div>
            </div>
            <div className="gap-8 items-center hidden sm:flex">
                <a href={DISCORD_URL}>
                    <DiscordLogo 
                        className="text-light-dark w-6"
                    />
                </a>
                <LanguageSelector/>
            </div>
            <div>
                <a href="https://opensea.io/collection/castle-heroes">
                    <DefaultButton className="flex">
                        <OpenSeaIcon className="w-6 h-6 mr-2" /> <FormattedMessage id={"Castle Heroes auf Open Sea"} />
                    </DefaultButton>
                </a>
            </div>
            <div ref={burgerRef}>
                <MenuBurgers
                    className="w-6 h-6 cursor-pointer lg:hidden shrink-0"
                    onClick={()=>setIsMenuOpen(!isMenuOpen)}
                />
            </div>
            {isMenuOpen&&<div ref={menuRef} className="absolute left-0 top-28 w-full bg-white" style={{zIndex: 9999}}>
                {menuElements.map(el => <div key={el.name} className="p-4 border-gray-300 border-b text-light-dark active:text-light-dark"
                    onClick={()=>menuClick(el)}
                >
                    <FormattedMessage id={el.name} />
                </div>)}

                <a className="block p-4 border-gray-300 border-b text-light-dark active:text-light-dark" href={DISCORD_URL}>
                    Discord
                </a>
                <div className={"block p-4 border-gray-300 border-b text-light-dark active:text-light-dark flex"}>
                    {["de", "fr", "it"].map(l => <>
                        <span onClick={()=>{
                            setLanguage(l as Language);
                            setIsMenuOpen(false);
                        }}
                              className={`${language==l?'underline':''}`}
                        >{l.toUpperCase()}</span>
                        {l!="it"&&<>&nbsp;&nbsp;|&nbsp;&nbsp;</>}
                    </>)}
                </div>
            </div>}
        </div>
    </div>;
}