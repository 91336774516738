import { useWeb3React } from "@web3-react/core";
import React, {ReactElement, useEffect, useState} from "react";
import Web3 from "web3";
import { CONTRACT_ABI } from "../../../abi";
import { connectors, ConnProvider } from "../../../eth/connectors";
import { shortenAccountNumber, switchNetwork } from "../../../eth/ethUtils";
import {CHAIN_ID, CONTRACT_ADDRESS, MINTING_ALLOWED} from "../../../eth_config";
import { DefaultButton } from "../../buttons/DefaultButton";
import { ConnectorModal } from "../../eth/ConnectorModal";
import SoldOutImageUrl from "../../../assets/mint/soldout.png";
import HeroesUrl from "../../../assets/mint/heroes.png";
import NFTPreviewUrl from "../../../assets/mint/nft-preview.gif";
import { FormattedMessage } from "react-intl";
import {
    isConnectorModalOpenSelector, saveProvider,
    setConnectorModalOpen,
    setTermsPopupOpen,
    useAppDispatch
} from "../../../app/store";
import {useDispatch, useSelector} from "react-redux";
import {ReactComponent as CheckboxEmpty} from "../../../assets/mint/checkbox-empty.svg";
import {ReactComponent as CheckboxChecked} from "../../../assets/mint/checkbox-checked.svg";
import {ReactComponent as Loading} from "../../../assets/mint/loading.svg";
import {Popup} from "../../common/Popup";
import {DISCORD_URL, useHomeUrl} from "../../../config";

const minGas = 260000;
const maxGas = 350000;

const NOT_IN_WHITELIST = "NOT_IN_WHITELIST";

export function MintSection() {
    const HOME_URL = useHomeUrl();

    const [isTermsChecked, setTermsChecked] = useState<boolean>(false);
    const [clicked, setClicked] = useState<boolean>(false);
    const [minting, setMinting] = useState<boolean>(false);
    const [transactionHash, setTransactionHash] = useState<string>("");

    const dispatch = useAppDispatch();
    const isWalletSelectorOpen = useSelector(isConnectorModalOpenSelector);

    const [error, setError] = useState<ReactElement|string|null>(null);
    const [success, setSuccess] = useState<boolean>(false);
    const [tokenId, setTokenId] = useState<string>("");

    const [loading, setLoading] = useState<boolean>(false);
    const [minted, setMinted] = useState<number>(0);

    const {
        library,
        chainId,
        account,
        activate,
        deactivate,
    } = useWeb3React();


    const getWeb3 = () => {
        return new Web3(library.provider);
    }
    const getContract = () => {
        const web3 = getWeb3();
        return new web3.eth.Contract(CONTRACT_ABI as any, CONTRACT_ADDRESS);
    }

    const currentEnv = chainId === 1 ? "https://etherscan.io/" : "https://goerli.etherscan.io/";

    const onProviderSelected = async (provider: ConnProvider) => {
        const providerConn = connectors[provider];
        dispatch(saveProvider(provider));
        await deactivate();
        try {
            console.log('activate', providerConn);
            const activationResult = await activate(providerConn);
            console.log('activation result', activationResult);
        } catch (e) {
            console.log('activation error', e);
        }
    };
    
    useEffect(()=>{
        if (!account) {
            return;
        }
        if (!library) return;
        if (chainId != CHAIN_ID) {
            switchNetwork(library, CHAIN_ID);
        }
        const contract = getContract();
        setLoading(true);
        (async ()=>{
            try {
                const balance = Number(await contract.methods.balanceOf(account).call());
                if (!isNaN(balance)) {
                    setMinted(balance);
                    if (balance >= 1) {
                        setSuccess(true);
                        setMinting(true);
                    }
                }
            } catch (e: any) {
                console.log(`error while balance request`);
                console.log(e);
            } finally {
                setLoading(false);
            }
        })();
    }, [account, chainId, library]);

    const onError = (e: any) => {
        if (typeof e === "string") {
            if (e === NOT_IN_WHITELIST) {
                setError(e);
            } else {
                setError(<FormattedMessage id={e} />);
            }
        } else if (e.message) {
            if (e.message.includes("User denied")) {
                setError(null);
            } else {
                setError(<div>{e.message}</div>);
            }
        } else {
            setError(<div>{e.toString()}</div>);
        }
        setMinting(false);
    };

    const mint = async () => {
        setClicked(true);
        if (!isTermsChecked || minting) return;
        if (transactionHash) return;

        setError(null);
        /*const proof = await fetch("api/?action=get_proof", {
            method: "POST",
            body: JSON.stringify({"address": account})
        }).then(r=>r.json());
        if (proof.length == 0) {
            onError(NOT_IN_WHITELIST);
            return;
        }*/
        const contract = getContract();
        const web3 = getWeb3();

        setMinting(true);
        try {
            let gl = maxGas;
            const amount = 1;
            try {
                const egl = await contract.methods.mint(1).estimateGas({
                    from: account,
                    to: CONTRACT_ADDRESS,
                    value: 0
                });
                console.log("Gas Limit estimate: " + egl);
                gl = egl > 0 && egl < (maxGas * amount) ? egl : (maxGas * amount);
                console.log("Gas limit set after sanity check: " + gl);
            }
            catch(err) {
                console.log(`cannot estimate gas: ${err}`);
            }
            let txHash = "";
            
            contract.methods.mint(1)
                .send({
                    gasLimit: gl,
                    from: account,
                    to: CONTRACT_ADDRESS,
                    value: 0,
                    maxPriorityFeePerGas: null,
                    maxFeePerGas: null
                })
                .on('error', function (error: any) {
                    console.log("on error");
                    console.log(error);
                    
                    onError(error);
                })
                .on('transactionHash', function (transactionHash: string) {
                    console.log("on transactionHash");
                    console.log(transactionHash);
                    txHash = transactionHash;

                    setTransactionHash(txHash);
                })
                .on('receipt', function (receipt: any) {
                    console.log("on receipt");
                    console.log(receipt);
                    const tokenId = receipt.events.Transfer.returnValues.tokenId;
                    setTokenId(tokenId);
                })
                .on('confirmation', function (confirmationNumber: any, receipt: any) {
                    console.log(confirmationNumber, receipt);
                })
                .then((res: any) => {
                    console.log(res);
                    setSuccess(true);
                })
                .catch((err: any) => {
                    console.log(`catch`);
                    console.log(err);
                    onError(err);

                    (async ()=>{
                        const tx = await web3.eth.getTransaction(txHash);
                        // @ts-ignore
                        let result = await web3.eth.call(tx, tx.blockNumber);

                        console.log(`err call result`);
                        console.log(result);

                        result = result.startsWith('0x') ? result : `0x${result}`

                        if (result && result.substr(138)) {
                            const reason = web3.utils.toAscii(result.substr(138))
                            console.log('Revert reason:', reason)
                        } else {
                            console.log('Cannot get reason - No return value')
                        }
                    })();
                });

        } catch (e: any) {
            console.error(e);
            onError(e);
        }
        
    };

    return <div className="flex justify-center">
        <div className="m-6 md:m-0 mt-16 md:mt-32 bg-white p-6 md:p-10 md:py-20 rounded-2xl flex flex-col md:flex-row items-center gap-4 md:gap-20
            w-[800px] max-w-full"
        >
            <div className="w-full md:w-auto md:shrink-0 md:-ml-20">
                <img src={SoldOutImageUrl} className="w-full md:w-64"/>
            </div>
            <div>
                {isWalletSelectorOpen && <ConnectorModal
                    onClose={() => dispatch(setConnectorModalOpen(false))}
                    onProviderSelected={onProviderSelected}
                />}

                <div>
                    <div className="text-active font-klint-pro font-bold">
                        Sold Out
                    </div>
                    <div className="text-dark font-bold font-klint-pro text-headline-3 mt-2">
                        <FormattedMessage id={"Leider zu spät..."} />
                    </div>
                    <div className="text-light-dark mt-2 font-klint-pro">
                        <FormattedMessage id={"Leider sind keine Castle Heroes mehr erhältlich. Aber Besuche die Kollektion auf {Opensea}."} 
                            values={{Opensea: <a href="https://opensea.io/collection/castle-heroes" className="text-active">Opensea</a>}}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>;
}
